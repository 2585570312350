import React, { useEffect, useRef, useState } from "react";
import aquarelle from "../../assets/images/aquarelle.png";
import logoScoutEurope from "../../assets/icons/logoScoutEurope.png";

export default function FirstPart() {
  return (
    <div className={"firstPart"}>
      <div className={"leftPartFirstPart"}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logoScoutEurope} alt="" className={"logoScoutEurope"} />
          <p className={"troupeName"}>
            <span style={{ color: "#4E66E4" }}>Groupe 1er Chantilly, </span>
            Groupe d’Aumale
          </p>
        </div>
        <div className={"centerPart"}>
          <h2 className={"mainTitle"}>
            Participez au{" "}
            <span
              style={{
                fontFamily:
                  'Comic Sans MS, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
                color: "#4E66E4",
                fontWeight: "500",
              }}
            >
              Cinquantenaire
            </span>{" "}
            de la troupe 1er Chantilly
          </h2>
          <a href="/formulaire">
            <div className={"participateButton"}>PARTCIPER À L'ÉVÉNEMENT</div>
          </a>
          <p className={"environ"}>Environ 5 min</p>

          <p className={"letter"}>
            Chère scout et ancien scout,
            <br />
            <br />
            Cher Louvetau ou Ancien Louvetau,
            <br />
            <br />
            Il y a plus de 50 ans un groupe d’amis aidé du Père Certaux a monté
            la troupe 1er Chantilly et rejoint la grande famille des scouts
            d’Europe. Depuis lors ce sont plus de 400 jeunes qui ont grandi au
            rythme de la troupe. Sous les couleurs du Vert et du blanc, vous
            avez vécu des aventures formidables dans différentes régions de
            France, découvrant des lieux exceptionnels. Années après Années,
            vous avez grandi en tant que Louveteau, puis scout, routier et peut
            être même servit en devenant chef à votre tour! Ce scoutisme qui
            vous a forgé dans votre vie d’homme, c’est ce que nous voulons
            célébrer ensemble !
            <br />
            <br />
            Avec la maîtrise actuelle, nous souhaitons fêter le cinquantenaire
            de la troupe et organiser une journée de commémoration le{" "}
            <span style={{ fontWeight: 600 }}>samedi 17 mai 2025</span> à
            Chantilly.
            <br />
            <br />
            Pour cette journée particulière nous invitons tous les anciens
            scouts, louveteaux, chefs et routiers avec leur famille. Nous
            n’oublions pas nos frères de la 3ème chantilly et bien sûr le groupe
            de la 1er Senlis dont les patrouilles sont toutes issues de la même
            histoire que la nôtre !
            <br />
            <br />
            Pour que cet événement puisse avoir lieu, nous avons besoin de vous
            ! Il est essentiel que vous vous inscriviez afin de reconstituer une
            base de données complètes et n’oublier personne dans l’organisation
            de cette journée.
            <br />
            <br />
            <span style={{ fontWeight: 600 }}>
              Alors merci de vous inscrire que vous soyez présent ou pas le jour
              J ! Merci de partager le lien à vos contacts de vos groupes scouts
              que nous puissions être le plus nombreux possible.
            </span>
            <br />
            <br />
            Il est également possible de déposer des informations sur le site ou
            par mail afin de partager les archives que vous avez en votre
            possession (Photos, listing, anecdotes…)
            <br />
            <br />
            D’autres informations suivront rapidement pour ceux inscrits !
            <br />
            <br />
            Un grand merci de votre soutien que nous puissions faire de cet
            évènement une journée chaleureuse et conviviale !
            <br />
            <br />
            Les maitrises de la 1er Chantilly Duc d’Aumale
            <br />
            50ans.groupe1.chantilly@gmail.com
          </p>
        </div>
      </div>
      <div className={"rightPartFirstPart"}>
        <img src={aquarelle} alt="" className={"aquarelle"} />
        <p className={"credit"}>© Crédit : </p>
      </div>
    </div>
  );
}
