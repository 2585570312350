import React, { useEffect, useState } from "react";
import "./_homePage.scss";
import imgLouvetaux from "../../assets/images/imgLouvetaux.png";
import imgTroupe from "../../assets/images/imgTroupe.png";
import flag from "../../assets/images/flag.png";
import Welcome from "../../components/StepsForm/Welcome";
import InfosPerso from "../../components/StepsForm/InfosPerso";
import InfosPersos2 from "../../components/StepsForm/InfosPersos2";
import LeGroupeEtVous from "../../components/StepsForm/LeGroupeEtVous";
import InfosLouveautaux from "../../components/StepsForm/InfosLouveautaux";
import InfosScouts from "../../components/StepsForm/InfosScouts";
import Participate from "../../components/StepsForm/Participate";
import Remarques from "../../components/StepsForm/Remarques";
import Remerciement from "../../components/StepsForm/Remerciement";
import HeaderHomePage from "./HeaderHomePage";
import InfosRoutiers from "../../components/StepsForm/InfosRoutiers";
import { db, storage } from "../../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { generateUniqueFirestoreId } from "../../firebase/utils";

export default function HomePage() {
  const [step, setStep] = useState(1);

  const [width, setWidth] = useState(800);

  // Infos

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  const [birthDate, setBirthDate] = useState("");
  const [situationMatrimoniale, setSituationMatrimoniale] = useState("");
  const [nombreEnfants, setNombreEnfants] = useState(0);

  const [entryYear, setEntryYear] = useState("");
  const [exitYear, setExitYear] = useState("");

  const [haveBeenLouveteau, setHaveBeenLouveteau] = useState(true);
  const [entryYearLouveteaux, setEntryYearLouveteaux] = useState("");
  const [exitYearLouveteaux, setExitYearLouveteaux] = useState("");

  const [haveBeenScout, setHaveBeenScout] = useState(true);
  const [entryYearScouts, setEntryYearScouts] = useState("");
  const [exitYearScouts, setExitYearScouts] = useState("");
  const [scoutsResponsabilitiesSelected, setScoutsResponsabilitiesSelected] =
    useState([]);

  const [haveBeenRoutier, setHaveBeenRoutier] = useState(true);
  const [entryYearRoutiers, setEntryYearRoutiers] = useState("");
  const [exitYearRoutiers, setExitYearRoutiers] = useState("");

  const [notes, setNotes] = useState("");
  const [photos, setPhotos] = useState([]);

  const [participation, setParticipation] = useState(true);
  const [participationNumber, setParticipationNumber] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", Update);
    Update();
    return () => window.removeEventListener("resize", Update);
  }, []);

  function Update() {
    setWidth(window.innerWidth);
  }

  const submitForm = (e) => {
    e.preventDefault();
    setStep((prevState) => prevState + 1);
  };

  const submitAll = async (e) => {
    e.preventDefault();

    const docId = generateUniqueFirestoreId();

    const urls = [];
    for (let i = 0; i < photos.length; i++) {
      const id = generateUniqueFirestoreId();
      const fileRef = ref(storage, `images/${docId}/${id + ".jpeg"}`);
      await uploadString(fileRef, photos[i], "data_url");
      const url = await getDownloadURL(fileRef);
      urls.push(url);
    }

    const queryCollection = doc(db, "formulaires", docId);
    await setDoc(queryCollection, {
      name: name,
      firstName: firstName,
      phone: phone,
      email: email,
      address: address,
      birthDate: birthDate,
      maritalStatus: situationMatrimoniale,
      numberOfChildren: nombreEnfants,
      entryYear: entryYear,
      exitYear: exitYear,
      haveBeenLouveteau: haveBeenLouveteau,
      entryYearLouveteaux: entryYearLouveteaux,
      exitYearLouveteaux: exitYearLouveteaux,
      haveBeenScout: haveBeenScout,
      entryYearScouts: entryYearScouts,
      exitYearScouts: exitYearScouts,
      scoutsResponsibilitiesSelected: scoutsResponsabilitiesSelected,
      haveBeenDriver: haveBeenRoutier,
      entryYearDriver: entryYearRoutiers,
      exitYearDriver: exitYearRoutiers,
      notes: notes,
      photos: urls,
      participation: participation,
      participationNumber: participationNumber,
      date: new Date().toISOString(),
    });
    setStep(10);

    window.open(
      "https://www.helloasso.com/associations/agse-groupe-1re-chantilly/evenements/50-ans-1ere-chantilly",
      "_blank",
    );
  };

  return (
    <div className={"homePage"}>
      {step === 1 ? null : <HeaderHomePage step={step} setStep={setStep} />}
      {step === 1 ? (
        <Welcome submitForm={submitForm} />
      ) : step === 2 ? (
        <InfosPerso
          submitForm={submitForm}
          width={width}
          name={name}
          setName={setName}
          firstName={firstName}
          setFirstName={setFirstName}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          address={address}
          setAddress={setAddress}
        />
      ) : step === 3 ? (
        <InfosPersos2
          submitForm={submitForm}
          width={width}
          situationMatrimoniale={situationMatrimoniale}
          setSituationMatrimoniale={setSituationMatrimoniale}
          nombreEnfants={nombreEnfants}
          setNombreEnfants={setNombreEnfants}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
        />
      ) : step === 4 ? (
        <LeGroupeEtVous
          submitForm={submitForm}
          width={width}
          entryYear={entryYear}
          setEntryYear={setEntryYear}
          exitYear={exitYear}
          setExitYear={setExitYear}
        />
      ) : step === 5 ? (
        <InfosLouveautaux
          submitForm={submitForm}
          width={width}
          haveBeenLouveteau={haveBeenLouveteau}
          setHaveBeenLouveteau={setHaveBeenLouveteau}
          entryYearLouveteaux={entryYearLouveteaux}
          setEntryYearLouveteaux={setEntryYearLouveteaux}
          exitYearLouveteaux={exitYearLouveteaux}
          setExitYearLouveteaux={setExitYearLouveteaux}
        />
      ) : step === 6 ? (
        <InfosScouts
          submitForm={submitForm}
          haveBeenScout={haveBeenScout}
          setHaveBeenScout={setHaveBeenScout}
          entryYearScouts={entryYearScouts}
          setEntryYearScouts={setEntryYearScouts}
          exitYearScouts={exitYearScouts}
          setExitYearScouts={setExitYearScouts}
          scoutsResponsabilitiesSelected={scoutsResponsabilitiesSelected}
          setScoutsResponsabilitiesSelected={setScoutsResponsabilitiesSelected}
        />
      ) : step === 7 ? (
        <InfosRoutiers
          submitForm={submitForm}
          haveBeenRoutier={haveBeenRoutier}
          setHaveBeenRoutier={setHaveBeenRoutier}
          entryYearRoutiers={entryYearRoutiers}
          setEntryYearRoutiers={setEntryYearRoutiers}
          exitYearRoutiers={exitYearRoutiers}
          setExitYearRoutiers={setExitYearRoutiers}
        />
      ) : step === 8 ? (
        <Remarques
          submitForm={submitForm}
          step={step}
          width={width}
          notes={notes}
          setNotes={setNotes}
          photos={photos}
          setPhotos={setPhotos}
        />
      ) : step === 9 ? (
        <Participate
          submitForm={submitAll}
          width={width}
          participation={participation}
          setParticipation={setParticipation}
          participationNumber={participationNumber}
          setParticipationNumber={setParticipationNumber}
        />
      ) : step === 10 ? (
        <Remerciement />
      ) : null}

      <div className={"rightPart"} style={{ display: width < 980 && "none" }}>
        <img src={imgLouvetaux} alt="" className={"firstImage"} />
        <div style={{ display: "flex", gap: 5 }}>
          <img src={imgTroupe} alt="" className={"secondImage"} />
          <img src={flag} alt="" className={"secondImage"} />
        </div>
      </div>
    </div>
  );
}
